import React, {useEffect, useState} from 'react';
import {getCookie} from 'library/cookie/get';
import {CookiesNames} from 'library/cookie/cookiesNames';
import {
    Box, Button,
    Modal,
    Paper,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,
} from '@mui/material';
import {BlogPostRssFeedListResponse} from 'model/api/blogPostRssFeedListResponse/types';
import {BlogRssFeedTableRow} from 'screens/blog/component/BlogRssFeedTableRow';

const modalInnerStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
};

export const BlogRssFeedListScreen = () => {
    const [rssFeedList, setRssFeedList] = useState<
        BlogPostRssFeedListResponse['rssFeedList'] | undefined
    >(undefined);

    const [detailedRssContent, setDetailedRssContent] = useState<
        {contentText: string | null, imageUrl: string | null} | undefined
    >(undefined);

    const loadPosts = async () => {
        const response = await fetch(
            '/admin-api/blog-post/rss-feed/get-all',
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Authorization: `Bearer ${getCookie(CookiesNames.JWT_KEY)}`,
                    'Content-Type': 'application/json',
                },
            },
        );
        const postsJson: BlogPostRssFeedListResponse = await response.json();
        setRssFeedList(postsJson.rssFeedList);
    };

    useEffect(() => {
        loadPosts();
    }, []);

    if (rssFeedList === undefined) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <Typography
                component="h1"
                variant="h3"
                mb={2}
            >
                Blog RSS Feed list
            </Typography>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Original URL</TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell>Original perex</TableCell>
                            <TableCell>Generate article</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rssFeedList.map((rssFeed) => (
                            <BlogRssFeedTableRow
                                key={rssFeed.id}
                                rssFeed={rssFeed}
                                onSetDetailedContent={setDetailedRssContent}
                                onGenerateSuccess={() => loadPosts()}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Modal
                open={detailedRssContent !== undefined}
                onClose={() => setDetailedRssContent(undefined)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalInnerStyle}>
                    {detailedRssContent?.imageUrl && (
                        <div>
                            <img
                                style={{maxWidth: '100%', maxHeight: '100%'}}
                                src={detailedRssContent.imageUrl}
                                alt="detailed content"
                            />
                            <hr />
                        </div>
                    )}
                    {detailedRssContent?.contentText && (
                        <div dangerouslySetInnerHTML={{__html: detailedRssContent.contentText}} />
                    )}
                </Box>
            </Modal>
        </div>
    );
};
