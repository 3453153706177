import {
    AppBar, Box, List, ListItem, ListItemButton, ListItemText, Toolbar, Typography,
} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {useState} from 'react';

type SectionName = 'proof' | 'cinema' | 'blog';

export const TopBar = () => {
    const navigate = useNavigate();

    const [hoveredSection, setHoveredSection] = useState<SectionName | undefined>(undefined);

    return (
        <Box sx={{display: 'flex'}}>
            <AppBar component="nav" sx={{display: 'flex'}}>
                <Toolbar>
                    <List sx={{display: 'flex'}}>
                        <ListItem
                            disablePadding
                        >
                            <ListItemButton
                                sx={{ textAlign: 'center' }}
                                onClick={() => navigate('/')}
                            >
                                <ListItemText primary="Home" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton
                                sx={{ textAlign: 'center' }}
                                onClick={() => navigate('/uploads')}
                            >
                                <ListItemText primary="Uploads" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem
                            disablePadding
                            onMouseEnter={() => setHoveredSection('proof')}
                            onMouseLeave={() => setHoveredSection(undefined)}
                        >
                            <ListItemButton
                                sx={{ textAlign: 'center'}}
                            >
                                <ListItemText primary="Proof read" />
                            </ListItemButton>
                            {hoveredSection === 'proof' && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        bottom: '-180px',
                                        width: '100%',
                                    }}
                                >
                                    <List sx={{backgroundColor: 'primary.light'}}>
                                        <ListItem disablePadding onClick={() => navigate('/proof-read/movie-names')}>
                                            <ListItemButton>Movie names</ListItemButton>
                                        </ListItem>
                                        <ListItem disablePadding onClick={() => navigate('/proof-read/descriptions')}>
                                            <ListItemButton>Movie descriptions + reasons</ListItemButton>
                                        </ListItem>
                                        <ListItem disablePadding onClick={() => navigate('/proof-read/user-proofread-description-stats')}>
                                            <ListItemButton>Stats proof description</ListItemButton>
                                        </ListItem>
                                    </List>
                                </Box>
                            )}
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton
                                sx={{ textAlign: 'center' }}
                                onClick={() => navigate('/jobs')}
                            >
                                <ListItemText primary="Jobs" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem
                            disablePadding
                            onMouseEnter={() => setHoveredSection('cinema')}
                            onMouseLeave={() => setHoveredSection(undefined)}
                        >
                            <ListItemButton
                                sx={{ textAlign: 'center'}}
                            >
                                <ListItemText primary="Cinema" />
                            </ListItemButton>
                            {hoveredSection === 'cinema' && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        bottom: '-120px',
                                        width: '100%',
                                    }}
                                >
                                    <List sx={{backgroundColor: 'primary.light'}}>
                                        <ListItem disablePadding onClick={() => navigate('/cinema/kinomaniak-movies-mapping')}>
                                            <ListItemButton>Kinomaniak movies</ListItemButton>
                                        </ListItem>
                                        <ListItem disablePadding onClick={() => navigate('/cinema/kinomaniak-branches-mapping')}>
                                            <ListItemButton>Kinomaniak branches</ListItemButton>
                                        </ListItem>
                                    </List>
                                </Box>
                            )}
                        </ListItem>
                        <ListItem
                            disablePadding
                            onMouseEnter={() => setHoveredSection('blog')}
                            onMouseLeave={() => setHoveredSection(undefined)}
                        >
                            <ListItemButton
                                sx={{ textAlign: 'center'}}
                            >
                                <ListItemText primary="Blog" />
                            </ListItemButton>
                            {hoveredSection === 'blog' && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        bottom: '-120px',
                                        width: '100%',
                                    }}
                                >
                                    <List sx={{backgroundColor: 'primary.light'}}>
                                        <ListItem disablePadding onClick={() => navigate('/blog/rss-feeds-list')}>
                                            <ListItemButton>RSS feeds</ListItemButton>
                                        </ListItem>
                                        <ListItem disablePadding onClick={() => navigate('/blog/posts-list')}>
                                            <ListItemButton>Posts list</ListItemButton>
                                        </ListItem>
                                    </List>
                                </Box>
                            )}
                        </ListItem>
                    </List>
                </Toolbar>
            </AppBar>
        </Box>
    );
};
