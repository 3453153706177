import React, {useEffect, useState} from 'react';
import {getCookie} from 'library/cookie/get';
import {CookiesNames} from 'library/cookie/cookiesNames';
import {
    Box,
    Modal,
    Paper,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,
} from '@mui/material';
import {BlogPostListResponse} from 'model/api/blogPostListResponse/types';
import {BlogPostTableRow} from 'screens/blog/component/BlogPostTableRow';

const modalInnerStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    height: '100%',
    overflow: 'auto',
};

export const BlogPostListScreen = () => {
    const [postsList, setPostsList] = useState<
        BlogPostListResponse['posts'] | undefined
    >(undefined);

    const [detailedArticleContent, setDetailedArticleContent] = useState<
        {contentHtml: string, heading: string} | undefined
    >(undefined);

    const loadPosts = async () => {
        const response = await fetch(
            '/admin-api/blog-post/list/get-all',
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Authorization: `Bearer ${getCookie(CookiesNames.JWT_KEY)}`,
                    'Content-Type': 'application/json',
                },
            },
        );
        const postsJson: BlogPostListResponse = await response.json();
        setPostsList(postsJson.posts);
    };

    useEffect(() => {
        loadPosts();
    }, []);

    if (postsList === undefined) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <Typography
                component="h1"
                variant="h3"
                mb={2}
            >
                Blog post list
            </Typography>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Domain</TableCell>
                            <TableCell>Original URL</TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell>Generated post</TableCell>
                            <TableCell>PublishedAt</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {postsList.map((post) => (
                            <BlogPostTableRow
                                key={post.blogPostDomainId}
                                post={post}
                                onSetDetailedContent={setDetailedArticleContent}
                                onSuccessPublish={() => loadPosts()}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Modal
                open={detailedArticleContent !== undefined}
                onClose={() => setDetailedArticleContent(undefined)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalInnerStyle}>
                    {detailedArticleContent && (
                        <div style={{padding: '24px 0'}}>
                            <h1>{detailedArticleContent.heading}</h1>
                            <div dangerouslySetInnerHTML={{__html: detailedArticleContent.contentHtml}} />
                        </div>
                    )}
                </Box>
            </Modal>
        </div>
    );
};
