import {Button, TableCell, TableRow} from '@mui/material';
import React, {useState} from 'react';
import {BlogPostRssFeedListResponse} from 'model/api/blogPostRssFeedListResponse/types';
import {getCookie} from 'library/cookie/get';
import {CookiesNames} from 'library/cookie/cookiesNames';

interface Props {
    rssFeed: BlogPostRssFeedListResponse['rssFeedList'][number]
    onSetDetailedContent: (payload: {contentText: string | null, imageUrl: string | null}) => void
    onGenerateSuccess: () => void;
}

export const BlogRssFeedTableRow = ({rssFeed, onSetDetailedContent, onGenerateSuccess}: Props) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleFetchGenerate = async () => {
        setIsLoading(true);
        await fetch(
            `/admin-api/blog-post/creation/generate-from-rss-feed-from-domain?domain=cz&rssFeedId=${rssFeed.id}`,
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Authorization: `Bearer ${getCookie(CookiesNames.JWT_KEY)}`,
                    'Content-Type': 'application/json',
                },
            },
        );
        setIsLoading(false);
        onGenerateSuccess();
    };

    return (
        <TableRow
            key={rssFeed.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell component="th" scope="row">
                {rssFeed.id}
            </TableCell>
            <TableCell component="th" scope="row">
                <a href={rssFeed.sourceUrl} target="_blank">
                    SOURCE
                </a>
            </TableCell>
            <TableCell component="th" scope="row">
                {rssFeed.title}
            </TableCell>
            <TableCell component="th" scope="row">
                {rssFeed.contentText
                    ? (
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                onSetDetailedContent({
                                    contentText: rssFeed.contentText,
                                    imageUrl: rssFeed.imageUrl,
                                });
                            }}
                        >
                            Show Perex
                        </a>
                    )
                    : <span>Unable to get</span>}

            </TableCell>
            <TableCell component="th" scope="row">
                <Button
                    variant="contained"
                    color="primary"
                    disabled={rssFeed.generatedInDomainsMap.cz || isLoading}
                    onClick={handleFetchGenerate}
                >
                    CZ
                    {isLoading && ' (Loading...)'}
                </Button>
                {rssFeed.generatedInDomainsMap.cz && (
                    <span>(generated in CZ)</span>
                )}
            </TableCell>
        </TableRow>
    );
};
