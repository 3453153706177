import {Button, TableCell, TableRow} from '@mui/material';
import React, {useState} from 'react';
import {getCookie} from 'library/cookie/get';
import {CookiesNames} from 'library/cookie/cookiesNames';
import {BlogPostListResponse} from 'model/api/blogPostListResponse/types';

interface Props {
    post: BlogPostListResponse['posts'][number]
    onSetDetailedContent: (payload: {contentHtml: string, heading: string}) => void;
    onSuccessPublish: () => void;
}

export const BlogPostTableRow = ({post, onSetDetailedContent, onSuccessPublish}: Props) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleFetchPostToWordpress = async () => {
        setIsLoading(true);
        await fetch(
            `/admin-api/blog-post/creation/publish-to-wordpress?domain=cz&blogPostDomainId=${post.blogPostDomainId}`,
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Authorization: `Bearer ${getCookie(CookiesNames.JWT_KEY)}`,
                    'Content-Type': 'application/json',
                },
            },
        );
        await onSuccessPublish();
        setIsLoading(false);
    };

    return (
        <TableRow
            key={post.blogPostDomainId}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell component="th" scope="row">
                {post.blogPostDomainId}
            </TableCell>
            <TableCell component="th" scope="row">
                {post.domain}
            </TableCell>
            <TableCell component="th" scope="row">
                <a href={post.originalUrl} target="_blank">
                    SOURCE
                </a>
            </TableCell>
            <TableCell component="th" scope="row">
                {post.title}
            </TableCell>
            <TableCell component="th" scope="row">
                <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        onSetDetailedContent({contentHtml: post.contentHtml, heading: post.title});
                    }}
                >
                    Show Article
                </a>
            </TableCell>
            <TableCell component="th" scope="row">
                {post.publishedAt === null
                    ? (
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={isLoading}
                            onClick={handleFetchPostToWordpress}
                        >
                            Publish to CZ
                            {isLoading && ' (Loading...)'}
                        </Button>
                    )
                    : post.publishedAt}
            </TableCell>
        </TableRow>
    );
};
